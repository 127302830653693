import { Route, RouteProps } from "react-router-dom";

export interface LayoutRouteProps extends RouteProps {
  layout: React.ComponentType;
}

export const LayoutRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}: LayoutRouteProps) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>{Component && <Component {...props} />}</Layout>
      )}
    />
  );
};
