import { useCallback, useMemo, useState } from 'react';

interface StableActions<V> {
  add: (value: V) => void;
  addMany: (...values: V[]) => void;
  remove: (value: V) => void;
  removeMany: (...values: V[]) => void;
  toggle: (value: V) => void;
  reset: () => void;
}

export interface UseHashSetActions<V> extends StableActions<V> {
  has: (value: V) => boolean;
}

export const useHashSet = <K, V>(hash: (v: V) => K): [V[], UseHashSetActions<V>] => {
  const [map, setMap] = useState(new Map<K, V>());

  const stableActions = useMemo<StableActions<V>>(() => {
    const add = (item: V) => setMap((prev) =>
      new Map([...Array.from(prev.entries()), [hash(item), item]]));

    const addMany = (...items: V[]) => setMap((prev) =>
      new Map(
        [...Array.from(prev.entries()), ...items.map(i => [hash(i), i] as [K, V])]));

    const remove = (item: V) => setMap((prev) =>
      new Map([...Array.from(prev.entries()).filter(e => e[0] !== hash(item))]));

    const removeMany = (...items: V[]) =>
      setMap((prev) =>
        new Map([...Array.from(prev.entries()).filter(e => !items.find(item => hash(item) === e[0]))])
      );


    const toggle = (item: V) =>
      setMap((prev) =>
        prev.has(hash(item))
          ? new Map(Array.from(prev).filter((i) => i[0] !== hash(item)))
          : new Map([...Array.from(prev), [hash(item), item]])
      );

    return { add, addMany, remove, removeMany, toggle, reset: () => setMap(new Map()) };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const utils: UseHashSetActions<V> = {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    has: useCallback((item) => map.has(hash(item)), [map]),
    ...stableActions,
  };

  return [useMemo(() => Array.from(map.values()), [map]), utils];
};
