import { notApplicable } from "../../components/form-utils/utils";
import { extraTextareaProps } from "../common";

export const securityTwo = {
  location_secured: {
    name: "location_secured",
    label:
      "6.2.1. a) Prezentați o descriere succintă a modului în care sunt securizate spațiile proprii.",
    ...extraTextareaProps,
    info: "6.2.1. a), b) şi c) Această întrebare vizează locurile unde există limite exterioare vizibile ale spaţiilor, de exemplu, garduri şi porţi. Autoritatea vamală se aşteaptă ca toate ferestrele externe şi interne, porţile şi gardurile să fie securizate, de exemplu, cu dispozitive de închidere şi încuietori sau măsuri alternative de monitorizare a accesului sau prin măsuri de control, precum sisteme externe/interne de alarmă antifurt sau CCTV (sisteme TV cu circuit închis).Pentru întrebările de la lit. a) - c) prezentați detaliile privind modul în care este verificată respectarea acestor proceduri, frecvenţa verificărilor asupra clădirilor şi gardurilor, modul în care sunt raportate şi soluţionate incidentele de securitate trebuie să fie cuprinse în documentul solicitat în răspunsurile la întrebarea de la subpunctul 6.1.2. lit. a) sau b). Indicaţi aici trimiterea la alineatul, secţiunea sau pagina corespunzătoare (revizia/data) din respectivul document.",
  },
  procedures_verified: {
    name: "procedures_verified",
    label: "Cum este verificată respectarea acestor proceduri?",
    ...extraTextareaProps,
  },
  checks_buildings: {
    name: "checks_buildings",
    label:
      "b) Cum, de către cine și la ce intervale se efectuează verificările în ceea ce privește gardurile și clădirile?",
    ...extraTextareaProps,
  },
  register_checks: {
    name: "register_checks",
    label: "Cum sunt înregistrate aceste verificări și rezultatele acestora?",
    ...extraTextareaProps,
  },
  register_checks_solution: {
    name: "register_checks_solution",
    label: "c) Cum sunt raportate și soluționate incidentele de securitate?",
    ...extraTextareaProps,
  },
  access_possibilities: {
    name: "access_possibilities",
    label: "6.2.2. a) Ce posibilități de acces există?",
    ...extraTextareaProps,
    info: "6.2.2. a) şi b) Trebuie să enumeraţi toate punctele de acces, de preferinţă făcând referire la planul sediului; includeţi ieşirile de incendiu care dau spre scări de acces; faceţi distincţie între căile de acces destinate încărcării/descărcării mărfurilor, cele pentru utilităţi, ghişeele pentru accesul publicului, zonele de odihnă ale şoferilor; precizaţi locurile în care se află birourile agenţilor de pază/cabine de pază. Descrierea realizată de solicitant a modului de respectare a acestor proceduri trebuie să includă, acolo unde cazul, tipul de CCTV (de exemplu, cameră statică sau cu sistem de rotire panoramică, înclinare şi distanţă focală variabilă), modalitatea de control al căilor de acces şi modul de folosire a imaginilor - proactiv sau reactiv. În plus, faţă de mijloacele de control al accesului extern, trebuie să descrieţi mijloacele de control al accesului intern, inclusiv, dacă este cazul, accesul intern în cadrul spaţiilor comune. Confirmaţi dacă spaţiile funcţionează încontinuu (de exemplu, munca în schimburi) sau există un program normal de lucru.",
  },
  access_respected: {
    name: "access_respected",
    label: "b) Cum sunt acestea respectate?",
    ...extraTextareaProps,
  },
  access_limits: {
    name: "access_limits",
    label:
      "c) Există limite în ceea ce privește programul de lucru la fiecare punct de acces?",
    ...extraTextareaProps,
  },
  premises_light: {
    name: "premises_light",
    label:
      "6.2.3. Sunt sediile iluminate în mod adecvat (de exemplu, lumină continuă, senzori de mișcare, etc.)?",
    ...extraTextareaProps,
    info: "6.2.3. Dacă este cazul, includeţi şi detalii privind generatoarele sau dispozitivele de rezervă existente pentru a asigura iluminatul continuu atunci când alimentarea locală cu energie electrică este întreruptă, precum şi modul în care acestea sunt întreţinute.",
  },
  manage_keys: {
    name: "manage_keys",
    label:
      "6.2.4. Cum se desfășoară administrarea cheilor în cadrul companiei (de exemplu, amplasare, acces, înregistrare)?",
    ...extraTextareaProps,
    info: `6.2.4. Precizaţi cum sunt identificate cheile şi ce proceduri există pentru prevenirea utilizării abuzive şi soluţionarea cazurilor de pierdere a acestora. Trebuie să existe proceduri prin care numai personalul autorizat să aibă acces la cheile clădirilor, ale spaţiilor, sălilor, zonelor securizate, fişetelor, ale seifurilor, vehiculelor şi echipamentelor încuiate. Procedurile trebuie, de asemenea, să includă:
<ul>
<li>a) spaţiul special amenajat în care sunt păstrate cheile;</li>
<li>b) persoana responsabilă de controlul securităţii cheilor;</li>
<li>c) înregistrarea momentului în care sunt luate şi returnate cheile, precum şi a numelui persoanei şi a motivului;</li>
<li>d) măsurile prevăzute în caz de pierdere sau nereturnarea a cheilor.</li>
</ul>
Furnizaţi detalii privind procedurile de încuiere şi, dacă este cazul, persoanele care deţin cheile principale pentru încuierea spaţiilor pe timpul nopţii şi redeschiderea acestora în următoarea zi lucrătoare. Furnizaţi detalii privind alte dispozitive tip „cheie" precum „cheile radiocomandate" (de exemplu, pentru operarea de la distanţă a barierei din parcare) utilizate şi persoanele cărora le-au fost alocate.`,
  },
  has_described_procedure: {
    name: "has_described_procedure",
    label: "Există o procedură descrisă în acest sens?",
    defaultDepsValue: [{ described_procedure_attachments: [] }],
  },
  described_procedure_attachments: {
    name: "described_procedure_attachments",
    label: "Documente",
  },
  has_private_parking: {
    name: "has_private_parking",
    label:
      "6.2.5. a) Este permisă parcarea autoturismelor private în spațiile proprii?",
    dependencies: ["parking_person", "parking_approval"],
    defaultDepsValue: [
      { parking_person: { true: null, false: notApplicable } },
      { parking_approval: { true: null, false: notApplicable } },
      { parking_procedure_attachments: [] },
    ],
    info: `6.2.5. a), b), c) şi d) Procedurile trebuie să includă:
<ul>
<li>1) modul de controlare/înregistrare a vizitatorilor cu vehicule private care vizitează spaţiile;</li>
<li>2) modul în care controlaţi vehiculele personalului în spaţiile ;</li>
<li>3) zonele de parcare amenajate special pentru vizitatori şi personal care nu sunt aproape de zonele securizate, de exemplu platformele de încărcare, pentru evitarea posibilităţii de furt, obstrucţie sau amestec al mărfurilor;</li>
<li>4) verificarea respectării cerinţelor referitoare la parcare.</li>
</ul>
<ul>
<li>a) Explicaţi dacă vehiculele vizitatorilor sunt separate de cele ale personalului. Trebuie să furnizaţi detalii privind alte vehicule care au acces temporar la subdiviziune/subdiviziuni, de exemplu taxiurile sau un autobuz destinat transportului personalului. </li>
<li>b) Trebuie să vă asiguraţi că există proceduri prin care autorizaţia este revizuită şi actualizată în mod regulat pentru a lua în considerare modificările privind vehiculele personalului. Furnizaţi detalii dacă angajaţilor li se emit permise de parcare şi cu privire la mecanismul de intrare şi ieşire din parcare, de exemplu, o barieră accesată prin card.</li>
<li>c) Descrieţi procesele sau procedurile utilizate pentru verificarea vehiculelor, de exemplu, dacă barierele sunt operate de personal în timpul orelor de vârf pentru a controla respectarea distanţei regulamentare dintre vehicule şi pentru a asigura un control adecvat al tuturor vehiculelor.</li>
<li>d) Descrieţi reglementările scrise care vizează parcarea şi modul în care acestea sunt comunicate personalului. Confirmaţi dacă aceste reglementări au fost incluse în evaluarea de securitate.</li>
</ul>
`,
  },
  parking_person: {
    name: "parking_person",
    label: "b) Dacă răspunsul este da, pentru care persoane?",
    reverse: true,
    dependencies: ["has_private_parking"],
    ...extraTextareaProps,
  },
  parking_approval: {
    name: "parking_approval",
    label: "c) Cine acordă aprobarea?",
    reverse: true,
    dependencies: ["has_private_parking"],
    ...extraTextareaProps,
  },
  has_parking_verification: {
    name: "has_parking_verification",
    label: "d) Sunt verificate autoturismele (la intrare/ieșire)?",
    dependencies: ["has_private_parking"],
  },
  parking_procedure_attachments: {
    name: "parking_procedure_attachments",
    label: "Documente",
  },
  has_parking_procedure: {
    name: "has_parking_procedure",
    label: "e) Există regulamente scrise în acest sens?",
    dependencies: ["has_private_parking"],
  },
  access_buildings: {
    name: "access_buildings",
    label:
      "6.3.1. a) Descrieți succint modul în care este reglementată procedura de acces în spațiile proprii: clădiri, zone de producție, depozite etc.)?",
    ...extraTextareaProps,
    info: "6.3.1. a) şi b) Trebuie să descrieţi pe scurt procesul de acces în incinte, explicând clar, acolo unde este cazul, dacă sunt implicate procese specifice unui anumit sediu. Pentru o cerere care vizează mai multe sedii, poate fi utilă descrierea sau furnizarea unei imagini generale asupra sediilor. Procedurile trebuie să indice persoanele care au acces în anumite zone, clădiri şi săli şi modul în care este controlat accesul, deexemplu, prin intermediul codurilor de acces sau al cardurilor de acces. Restricţionările de acces trebuie să ţină cont de evaluarea riscurilor şi a ameninţărilor de la subpunctul 6.1.2 lit. a). Sistemele trebuie să aibă capacitatea de a identifica tentativele de acces neautorizat şi de a monitoriza astfel de încercări. Descrieţi sistemul utilizat pentru a identifica personalul şi a-l deosebi de vizitatori, de exemplu cărţi de identitate.",
  },
  verification_person: {
    name: "verification_person",
    label: "b) Cine verifică respectarea procedurilor prevăzute?",
    ...extraTextareaProps,
  },
  unauthorized_procedure: {
    name: "unauthorized_procedure",
    label:
      "6.3.2. a) Descrieți procedurile care trebuie urmate în cazul în care o persoană neautorizată sau un autoturism neautorizat este descoperit în spațiile proprii (în clădiri sau pe teren)?",
    info: `6.3.2. a) şi b) Răspunsul  trebuie să confirme detaliile făcând referire la evaluarea riscurilor şi a ameninţărilor descrisă la subpunctul 6.1.2 lit.a) şi b).`,
    ...extraTextareaProps,
  },
  procedure_communicated: {
    name: "procedure_communicated",
    label:
      "b) Cum sunt aceste proceduri comunicate personalului (de exemplu, plan de acțiune, instrucțiuni, cursuri de instruire etc.)?",
    ...extraTextareaProps,
  },
  plan_subdivisions: {
    name: "plan_subdivisions",
    label:
      "6.3.3. Prezentați un plan general pentru fiecare dintre subdiviziunile companiei dvs. implicate în activități care au legătură cu domeniul vamal (de exemplu, planul/proiectul grafic) din care să poată fi identificate căile de acces, limitele și amplasarea clădirilor, acolo unde este cazul.",
    ...extraTextareaProps,
    info: `6.3.3. Echipei de audit trebuie să li se pună la dispoziţie un plan al sediului. Chiar dacă planul nu este obligatoriu, orice ilustraţie îi va ajuta la pregătirea auditului şi poate reduce timpul necesar vizitei la sediu (sedii) solicitantului. Planul poate fi sau poate include o imagine a sediului din satelit/de pe internet, dacă este disponibilă. Imaginile sau planurile prezentate trebuie să cuprindă data la care au fost realizate şi, astfel, să poată fi identificabile în mod unic pentru a constitui o pistă de audit pentru cererea AEO.`,
  },
  plan_subdivisions_documents: {
    name: "plan_subdivisions_documents",
    label: "Documente plan general",
  },
  specify_location: {
    name: "specify_location",
    label:
      "6.3.4. Specificați pentru fiecare amplasament, dacă este cazul, denumirea companiilor care se află, de asemenea, în spațiile respective.",
    ...extraTextareaProps,
    info: "6.3.4. Trebuie să acordaţi o atenţie specială oricăror societăţi cu sediul în aceeași clădire, care sunt simpli locatari şi care nu sunt implicate în realizarea unei aprovizionări către sau pentru . Locatarii pot prezenta probleme deosebite de securitate, iar măsurile care vizează, de exemplu, o intrare separată şi un spaţiu separat în cadrul amplasamentului, trebuie să fie descrise pe scurt. De asemenea, consultaţi şi subsecțiunea 6.12.",
  },
  has_restrictions_loading: {
    name: "has_restrictions_loading",
    label:
      "6.4.1. Există norme/restricţii în ceea ce priveşte accesul la unităţile de încărcare?",
    dependencies: ["restrictions_loading"],
    info: `6.4.1. Integritatea unităţilor de încărcare trebuie asigurată, de exemplu, prin plasarea acestora sub monitorizare permanentă sau prin păstrarea lor într-o zonă sigură, încuiată ori prin inspecţie înainte de utilizare. Numai persoanele identificate şi autorizate în mod corespunzător trebuie să aibă acces la unităţile de încărcare. Procedurile trebuie să includă:
<ul>
<li>a) modul în care este controlat accesul la zona în care sunt ţinute unităţile de încărcare (de exemplu, personal, şoferi de camion externi etc.);</li>
<li>b) asigurarea accesului exclusiv pentru persoanele autorizate;</li>
<li>c) modul în care se realizează monitorizarea permanentă a unităţilor, de exemplu, personalul responsabil şi supleanții acestora.</li>
</ul>
`,
  },
  restrictions_loading: {
    name: "restrictions_loading",
    label: "Dacă răspunsul este da, cum sunt aplicate aceste restricţii?",
    placeholder: "Detalii",
    dependencies: ["has_restrictions_loading"],
  },
  unauthorized_access_measures: {
    name: "unauthorized_access_measures",
    label:
      "6.4.2. Descrieţi succint ce măsuri sunt instituite pentru a preveni accesul neautorizat şi manipularea frauduloasă a unităţilor de încărcare (în special în spaţii deschise de depozitare), (de exemplu, supraveghere permanentă, formarea personalului şi informarea acestora cu privire la riscuri, sigilii, instrucţiuni privind procedurile care trebuie urmate în cazul unor intrări neautorizate)?",
    ...extraTextareaProps,
    info: `6.4.2. Procedurile trebuie să includă:
<ul>
<li>a) numele persoanei responsabile căreia îi sunt raportate incidentele;</li>
<li>b) modul în care incidentele sunt raportate şi înregistrate;</li>
<li>c) măsurile care trebuie luate, inclusiv raportarea către autorităţile de aplicare a legii/conducerea de la nivel înalt;</li>
<li>d) revizuirea şi modificarea procedurilor existente;</li>
<li>e) înştiinţarea personalului cu privire la modificări;</li>
</ul>
Autoritatea vamală va solicita dovezi ale acestor verificări în timpul vizitei.`,
  },
  has_own_seals: {
    name: "has_own_seals",
    label:
      "6.4.3. a) Utilizaţi sigilii proprii pentru a preveni manipularea frauduloasă a mărfurilor?",
    dependencies: ["own_seals", "seals_not_used"],
    info: "6.4.3. a) şi b) Descrieţi tipurile de sigilii folosite şi standardele îndeplinite de sigiliile utilizate. Indicaţi numele producătorului, procedura de emitere a sigiliilor şi de înregistrare a emiterii, utilizării şi îndepărtării acestora. Documentaţi procedurile privind abordarea cazurilor de sigilii rupte sau alterate.",
    defaultDepsValue: [{ seals_details: null }, { seals_not_used: null }],
  },
  seals_details: {
    name: "seals_details",
    label:
      "Dacă răspunsul este da, ce fel de sigilii? Corespund aceste sigilii unor standarde specifice?",
    ...extraTextareaProps,
    dependencies: ["has_own_seals"],
  },
  seals_not_used: {
    name: "seals_not_used",
    label:
      "b) Cum vă asiguraţi că mărfurile nu sunt manipulate fraudulos dacă nu sunt utilizate sigilii?",
    ...extraTextareaProps,
    dependencies: ["has_own_seals"],
  },
  load_unit_measures: {
    name: "load_unit_measures",
    label:
      "6.4.4. Ce măsuri de control utilizaţi pentru verificarea unităţilor de încărcare (de exemplu, procesul de verificare în şapte puncte: peretele din faţă, partea stângă, partea dreaptă, podeaua, plafonul/acoperişul, interiorul/exteriorul uşilor, exterior/şasiu)?",
    ...extraTextareaProps,
    info: `6.4.4. În funcţie de tipul unităţii de încărcare folosite, se recomandă un proces de verificare în şapte puncte (pentru a include şi unitatea de tractare):
<ul>
<li>a) peretele din faţă;</li>
<li>b) partea stângă;</li>
<li>c) partea dreaptă;</li>
<li>d) podeaua;</li>
<li>e) tavanul/acoperişul;</li>
<li>f) uşile interioare/exterioare;</li>
<li>g) exteriorul/şasiul.</li>
</ul>
`,
  },
  load_unit_operators: {
    name: "load_unit_operators",
    label:
      "6.4.5. a) Cine este proprietarul/operatorul unităţilor de încărcare?",
    ...extraTextareaProps,
    info: `6.4.5. lit. a), b), c) şi d) Operaţiunile de întreţinere trebuie efectuate periodic, nu numai în cazul unor avarii sau în cazul unor incidente. În cazul în care operaţiunile de întreţinere au loc în exterior sau nu au loc sub supravegherea personalului societăţii, integritatea unităţilor de încărcare trebuie verificată la revenirea în spaţiile societăţii. Procedurile trebuie să includă:
<ul>
<li>a) cerinţele adresate personalului de a verifica integritatea unităţilor la revenirea în spaţiile societăţii;</li>
<li>b) verificările care trebuie realizate, precizând când şi de către cine;</li>
<li>c) modul în care procedurile sunt comunicate personalului;</li>
<li>d) controalele de gestiune şi frecvenţa acestora pentru a asigura reexaminarea unităţilor.</li>
</ul>
  Explicaţi dacă verificaţi periodic toate unităţile de încărcare atât înainte de acceptarea oricărei încărcături intrate, cât şi înainte de încărcarea mărfurilor pentru expediere şi dacă aţi inclus proceduri în documentele menţionate la subpunctul 6.1.2 lit. a) şi b).`,
  },
  load_unit_maintains: {
    name: "load_unit_maintains",
    label: "b) Cine întreţine/repară unităţile de încărcare?",
    ...extraTextareaProps,
  },
  has_regular_maintenance: {
    name: "has_regular_maintenance",
    label: "c) Există planuri privind întreţinerea periodică?",
  },
  has_external_maintenance: {
    name: "has_external_maintenance",
    label: "d) Sunt verificate lucrările externe de întreţinere?",
  },
  transportation_methods: {
    name: "transportation_methods",
    label:
      "6.5.1. a) Ce metode de transport utilizează în mod normal compania dvs.?",
    ...extraTextareaProps,
    info: `6.5.1. lit. a), b), c) şi d) Acest proces vizează deplasarea mărfurilor importate şi/sau exportate între spaţiile care vă aparţin şi peste frontieră. Trebuie să enumeraţi toate modurile de transport utilizate dinspre sau către spaţiile şi care pătrund în lanţul de aprovizionare internaţional. Indicaţi modul de transport utilizat. Dacă utilizaţi furnizori de servicii externi, de asemenea, să vă referiţi şi la subsecțiunea 6.12 (Servicii contractate).`,
  },
  external_service: {
    name: "external_service",
    label:
      "b) Compania dvs. efectuează toate activităţile sale de transport sau utilizează şi furnizori de servicii externi (de exemplu, expeditori/ transportatori)?",
    ...extraTextareaProps,
  },
  external_security_standards: {
    name: "external_security_standards",
    label:
      "c) Cum stabiliţi dacă expeditorul/transportatorul respectă standardele necesare de securitate (de exemplu, prin intermediul unui certificat de securitate, al unor declaraţii sau acorduri)?",
    ...extraTextareaProps,
  },
  external_measures: {
    name: "external_measures",
    label:
      "d) Luaţi şi alte măsuri privind activităţile de transport externalizate, în vederea respectării standardelor de securitate? Dacă este cazul, prezentaţi natura şi sfera de acţiune a măsurilor luate de dvs. în acest sens.",
    ...extraTextareaProps,
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
