import { Badge, Collapse } from "..";

export interface FilterCollapseProps {
  title: React.ReactNode;
  count?: number;
}

export const FilterCollapse = ({
  title,
  count,
  children,
}: React.PropsWithChildren<FilterCollapseProps>) => {
  return (
    <>
      <Collapse
        header={<h4 className="color-gray">{title}</h4>}
        children={children}
        extra={
          count ? (
            <Badge size="sm" children={count} round variant="fill" />
          ) : undefined
        }
      />
      <div className="divider"></div>
    </>
  );
};
