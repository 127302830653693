import { forwardRef, PropsWithChildren } from "react";
import classnames from "classnames";
import { ComponentSize } from "./types";

export interface BadgeProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "prefix"> {
  variant?: BadgeVariant;
  color?: "primary" | "secondary" | "success" | "danger" | (string & {});
  round?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  size?: ComponentSize;
  align?: React.CSSProperties["textAlign"];
  width?: string | number;
}

export type BadgeVariant = "fill" | "outline" | "subtle";

export const Badge = forwardRef<HTMLDivElement, PropsWithChildren<BadgeProps>>(
  (
    {
      size,
      color = "secondary",
      round,
      prefix,
      suffix,
      variant = "outline",
      className,
      children,
      align,
      style,
      width,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={classnames("badge", className, size, color, variant, {
          round,
        })}
        style={{
          textAlign: align,
          width,
          ...style,
        }}
        {...props}
      >
        {prefix && (
          <>
            {prefix}
            <span className="badge__space" />
          </>
        )}
        <span className="badge__text">{children}</span>
        {suffix && (
          <>
            <span className="badge__space" />
            {suffix}
          </>
        )}
      </div>
    );
  },
);
