export interface WhiteSpaceProps {
  inline?: boolean;
  /**
   * Vertical margin
   */
  v?: number | string;
  /**
   * Horizontal margin
   */
  h?: number | string;
}

export const WhiteSpace = ({
  inline,
  v = "10px",
  h = "0",
}: WhiteSpaceProps) => {
  return (
    <div
      style={{
        display: inline ? "inline-block" : undefined,
        paddingTop: v,
        paddingLeft: h,
      }}
    ></div>
  );
};
